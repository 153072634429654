:root {
  --accent-color: #4a48ff;
  --app-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #000;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
ul {
  margin: 0.5em 0;
  font-weight: 400;
}

* + h1,
* + h2,
* + h3 {
  margin-top: 1em;
}

input[type='text'] {
  border: solid 1px black;
  border-radius: 3px;
  padding: 2px;
}

button {
  background: var(--accent-color);
  color: #fff;
  border: none;
  border-radius: 1em;
  font-weight: 600;
  font-size: 0.8rem;
  padding: 0.3em 1em;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin: 2px;
  white-space: nowrap;
  vertical-align: center;
  outline: none;
  user-select: none;
}

button:active {
  opacity: 0.8;
}
button[disabled] {
  opacity: 0.5;
  color: #fff8;
}

a {
  color: var(--accent-color);
}
