.root {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background: #555;
  color: #fff;
  flex-direction: column;
}

.sk-cube-grid {
  width: 100px;
  height: 100px;
  color: #cccc;
}

.sk-cube-grid > div {
  width: 33%;
  height: 33%;
  background-color: currentColor;
  float: left;

  animation: sk-scaleDelay 1.3s infinite ease-in-out;
}

/*
 * Spinner positions
 * 1 2 3
 * 4 5 6
 * 7 8 9
 */

.sk-cube-grid > div:nth-child(1) {
  animation-delay: 0.2s;
}
.sk-cube-grid > div:nth-child(2) {
  animation-delay: 0.3s;
}
.sk-cube-grid > div:nth-child(3) {
  animation-delay: 0.4s;
}
.sk-cube-grid > div:nth-child(4) {
  animation-delay: 0.1s;
}
.sk-cube-grid > div:nth-child(5) {
  animation-delay: 0.2s;
}
.sk-cube-grid > div:nth-child(6) {
  animation-delay: 0.3s;
}
.sk-cube-grid > div:nth-child(7) {
  animation-delay: 0s;
}
.sk-cube-grid > div:nth-child(8) {
  animation-delay: 0.1s;
}
.sk-cube-grid > div:nth-child(9) {
  animation-delay: 0.2s;
}

@keyframes sk-scaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
