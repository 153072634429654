.list {
  margin: 0.5em 0;
  padding: 0;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.list > * {
  background: #eee;
  border-radius: 2px;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  color: #000;
}
.icon {
  object-fit: contain;
  width: 100%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon img {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
}
.editName {
  font-size: 1em;
}
.name {
  text-decoration: underline;
  padding: 0.4em;
}
.size,
.date {
  font-size: 0.8em;
}
