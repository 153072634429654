.head {
  grid-area: header;
  color: #eee;
  padding: 15px 10px 10px;
  font-size: 20px;
  display: flex;
}
.head a {
  color: #fff;
}
.middle {
  flex-grow: 1;
  padding: 0 10px;
}
.version {
  font-size: 14px;
}
