.slider {
  width: 100%;
  position: relative;
  border: solid 1px #888;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.75),
    rgba(255, 255, 255, 0.75)
  );
  backdrop-filter: blur(10px);
  border-radius: 2px;
  cursor: pointer;
  margin: 2px 0;
  outline: none;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.slider:focus {
  border-color: #fff;
}

.thumb,
.mark {
  position: absolute;
}
.thumb {
  top: 0;
  bottom: 0;
  background: #fff;
  width: 2px;
  border-right: solid 1px #000;
}
.mark {
  bottom: 0;
  height: 4px;
  background: #ccc;
  width: 2px;
  border-right: solid 1px #000;
}
.label,
.valueLabel {
  flex-grow: 1;
  flex-shrink: 0;
  z-index: 1;
  padding: 2px 4px;
}
.label {
  color: #fff;
  font-size: 0.9em;
  text-shadow: 0 0 2px #000;
}
.valueLabel {
  font-size: 0.8em;
  text-shadow: 0 0 2px #fff;
  vertical-align: center;
  color: #000;
  text-align: right;
  font-variant-numeric: tabular-nums;
}
