.currentColor {
  height: 30px;
  margin: 1px;
}

.colorButtons {
  background: #000;
  padding: 1px;
  display: inline-block;
  text-align: center;
}

.colorButton {
  width: 30px;
  height: 30px;
  margin: 0.5px;
  outline: none;
  border-radius: 3px;
}
