.root {
  width: 100vw;
  height: var(--app-height);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
  background: #444;
}

.main {
  grid-area: main;
  flex-grow: 1;
  background: #fff;
  color: #000;
}
.main p {
  line-height: 1.5;
  margin: 0.5em 0;
}
