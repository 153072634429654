.root {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'tools tools tools'
    'left canvas right'
    'status status status';
  user-select: none;
  overflow: hidden;
}
.root a {
  color: #fff;
}
.canvasContainer {
  grid-row: 1 / 4;
  grid-column: 1 / 4;
  overflow: auto;
  background: #333;
  cursor: crosshair;
}
.tools,
.left,
.right,
.status {
  z-index: 1;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}
.left {
  display: flex;
  max-width: 20vw;
  min-width: 90px;
  flex-direction: column;
}
.tools {
  grid-area: tools;
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
}
.cursorSlider {
  margin: 2px 10px;
}
.cursorCounter {
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
  min-width: 4em;
  font-size: 0.7em;
  text-align: right;
}

.canvas {
  background: #fff;
  flex-shrink: 0;
  flex-grow: 0;
  user-select: none;
}
.toolLabel {
  margin-top: 10px;
  margin-bottom: 3px;
}
.diagInfo {
  font-size: 0.7em;
}
.value {
  float: right;
  font-variant-numeric: tabular-nums;
  font-size: 80%;
}
.toolLabel:first-child {
  margin-top: 0;
}
.left {
  grid-area: left;
  flex-shrink: 0;
  width: 150px;
  padding: 10px;
}
.right {
  grid-area: right;
  flex-shrink: 0;
}
.status {
  grid-area: status;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.status > * {
  padding: 3px 5px;
}
.status > .value {
  width: 5em;
  text-align: right;
  flex-shrink: 0;
  flex-grow: 0;
}
