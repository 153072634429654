.root {
  display: flex;
  flex-direction: row;
}
.column {
  padding: 10px;
  width: 50%;
}
.input {
  width: 4em;
  text-align: center;
}
.invalid {
  background: #ffc5c5;
}

.features {
  list-style: none;
  padding-left: 0.2em;
  line-height: 1.5;
}

@media only screen and (max-width: 640px) {
  .root {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
}
